import React, { useContext, useState, useEffect } from 'react';
import ErrorModal from '../../../common/UI/ErrorModal';
import {
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useForm } from '../../../common/hooks/form-hook';
import { AuthContext } from '../../../common/context/auth-context';
import LoadingSpinner from '../../../common/UI/LoadingSpinner';
import { useHttpClient } from '../../../common/hooks/http-hook';
import { useGetMembers } from '../../../common/hooks/getmembers-hook';
import moment from 'moment-timezone';
import { AccountableUser } from '../../../types/accountableUser';

interface AddToxProps {
  open: boolean;
  close: (value: boolean) => void;
  userId?: string;
  vendorAlert?: any;
  clientTimeZone?: string;
  onAddTox?: () => void;
  getProgramUsers: boolean;
}

interface ToxFormValues {
  toxDate: string;
  Notes: string;
  member: string;
}

const schema = {
  toxDate: {
    presence: { allowEmpty: true, message: 'is required' }
  },
  Notes: {
    presence: { allowEmpty: true, message: 'is required' }
  }
};

const AddTox: React.FC<AddToxProps> = ({
  open,
  close,
  userId,
  vendorAlert,
  clientTimeZone,
  onAddTox,
  getProgramUsers = false
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { hasError, handleChange, formState, clearFormState } = useForm<ToxFormValues>(schema);
  const auth = useContext(AuthContext);
  const [toxType, setToxType] = useState<number | ''>(''); // Start as blank
  const { members } = useGetMembers(sendRequest, auth);
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<string>(''); // Start as blank
  const [selfReport, setSelfReport] = useState(false);
  const [selectedUser, setSelectedUser] = useState(userId || '');
  const [dnaConfirmation, setDNAConfirmation] = useState<number>(1);
  const [kits, setKits] = useState<{ kitID: string }[]>([]);
  const [selectedKit, setSelectedKit] = useState('');
  const [vendorAlerts, setVendorAlerts] = useState<any[]>([]);
  const [selectedVendorAlert, setSelectedVendorAlert] = useState('');
  const [programMembers, setProgramMembers] = useState<AccountableUser[]>([]);

  const formatDate = (date: string, toxResult: string) => {
    let formattedDate = moment(date).tz(clientTimeZone || 'UTC');

    if (toxResult === 'Missed') {
      formattedDate = formattedDate.subtract(2, 'hours');
    }

    return formattedDate.format('MM/DD/YYYY hh:mm A');
  };

  useEffect(() => {
    if (open) {
      console.log(userId);
      clearFormState();
      setFile(null);
      if (userId) {
        console.log('selected user', userId);
        setSelectedUser(userId);
        getOpenKits(userId);
        getVendorAlerts(userId);
      }
      if (vendorAlert) {
        setResult(vendorAlert.toxResult);
        setToxType(6); // BAC
        handleChange({
          target: {
            name: 'toxDate',
            value: new Date(vendorAlert.toxDate).toISOString().substring(0, 10)
          }
        } as React.ChangeEvent<HTMLInputElement>);
        handleChange({
          target: {
            name: 'Notes',
            value: vendorAlert.Notes || ''
          }
        } as React.ChangeEvent<HTMLInputElement>);
        setSelectedVendorAlert(vendorAlert._id);
      } else {
        setToxType('');
        setResult('');
        setSelectedKit('');
      }
    }
  }, [open, userId, vendorAlert]);

  useEffect(() => {
    if (getProgramUsers) {
      fetchProgramUsers();
    }
  }, [getProgramUsers]);

  useEffect(() => {
    if (selectedUser) {
      getOpenKits(selectedUser);
      getVendorAlerts(selectedUser);
    }
  }, [selectedUser]);

  const getOpenKits = async (id: string) => {
    try {
      const myKits = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/getOpenKits`,
        'POST',
        JSON.stringify({ id }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      setKits(myKits.kits);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProgramUsers = async () => {
    try {
      const programUsers = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/accountable/programusersfilter`,
        'GET',
        null,
        { Authorization: 'Bearer ' + auth.token }
      );
      setProgramMembers(programUsers);
    } catch (err) {
      console.log(err);
    }
  };

  const getVendorAlerts = async (id: string) => {
    try {
      const alerts = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/accountable/getvendoralerts`,
        'POST',
        JSON.stringify({ user: { id }, count: 10 }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      const filteredAlerts = alerts.filter((alert: any) =>
        ['Missed', 'Positive'].includes(alert.toxResult)
      );
      setVendorAlerts(filteredAlerts);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!result || !toxType || (toxType === 5 && !selectedKit && result !== 'Missed')) {
      return; // Prevent saving if result, toxType, or kit (when Lab Confirmation) is not selected
    }
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/accountable/toxes`,
        'POST',
        JSON.stringify({
          member: selectedUser,
          toxDate: formState.values.toxDate,
          Notes: formState.values.Notes,
          toxResult: result,
          toxType,
          selfReport,
          kit: selectedKit,
          dnaConfirmation,
          vendorAlert:
            ['Missed', 'Positive'].includes(result) && toxType === 6 && selectedVendorAlert
              ? selectedVendorAlert
              : null,
          doUpdateToxCounts: true
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      if (file && responseData._id) {
        formData.append('toxID', responseData._id);
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/upload/uploadTox`,
          'POST',
          formData,
          {
            Authorization: 'Bearer ' + auth.token
          }
        );
      }
      onAddTox?.();
    } catch (err) {
      console.log(err);
    }
    close(false);
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    close(false);
  };

  const handleSelfReport = () => {
    setSelfReport(!selfReport);
  };

  return (
    <>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating User"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm">
        <DialogTitle>{'Add a Tox Result'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details from the toxicology test. The notes should include what the
            individual tested positive for if anything.
          </DialogContentText>
          <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
            <InputLabel id="member-label">Member</InputLabel>
            <Select
              sx={{ mt: 2 }}
              error={hasError('member')}
              fullWidth
              labelId="member-label"
              name="member"
              onChange={(e) => setSelectedUser(e.target.value)}
              value={selectedUser}>
              {getProgramUsers &&
                programMembers &&
                programMembers.map((member) => (
                  <MenuItem key={member._id} value={member._id}>
                    {member.lastName}, {member.firstName}
                  </MenuItem>
                ))}
              {members &&
                members.map((member) => (
                  <MenuItem key={member._id} value={member._id}>
                    {member.lastName}, {member.firstName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            sx={{ mt: 2 }}
            error={hasError('toxDate')}
            fullWidth
            helperText={hasError('toxDate') ? formState.errors.toxDate?.[0] : null}
            label="Toxicology Date"
            name="toxDate"
            onChange={handleChange}
            type="date"
            defaultValue={new Date().toISOString().substring(0, 10)}
            value={formState.values.toxDate || ''}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
            <InputLabel id="result-label">Result</InputLabel>
            <Select
              labelId="result-label"
              id="result-select"
              value={result}
              onChange={(e) => setResult(e.target.value as string)}>
              <MenuItem value="Positive">Positive</MenuItem>
              <MenuItem value="Missed">Missed</MenuItem>
              <MenuItem value="Negative">Negative</MenuItem>
              <MenuItem value="Update">Update</MenuItem>
              <MenuItem value="Collected">Lab Test Collected</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
            <InputLabel id="toxType-label">Tox Type</InputLabel>
            <Select
              labelId="toxType-label"
              id="toxType-select"
              value={toxType}
              onChange={(e) => setToxType(e.target.value as number)}>
              <MenuItem value={2}>10 Panel</MenuItem>
              <MenuItem value={4}>14 Panel</MenuItem>
              <MenuItem value={5}>Lab Confirmation</MenuItem>
              <MenuItem value={6}>BAC</MenuItem>
              <MenuItem value={7}>Medication Monitoring</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
            <InputLabel id="toxType-label">DNA Confirmation</InputLabel>
            <Select
              labelId="toxType-label"
              id="toxType-select"
              value={dnaConfirmation}
              onChange={(e) => setDNAConfirmation(e.target.value as number)}>
              <MenuItem value={1}>Not Applicable</MenuItem>
              <MenuItem value={2}>Pass</MenuItem>
              <MenuItem value={3}>Indeterminite</MenuItem>
              <MenuItem value={4}>Fail</MenuItem>
            </Select>
          </FormControl>
          {toxType === 5 &&
            result !== 'Missed' && ( // Show only if toxType is Lab Confirmation
              <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
                <InputLabel id="kit-label">Kit</InputLabel>
                <Select
                  labelId="kit-label"
                  id="kit-select"
                  value={selectedKit}
                  onChange={(e) => setSelectedKit(e.target.value)}>
                  {kits &&
                    kits.length > 0 &&
                    kits.map((kit) => (
                      <MenuItem value={kit.kitID} key={kit.kitID}>
                        {kit.kitID}
                      </MenuItem>
                    ))}
                  <MenuItem value="not_listed">Kit Not Listed</MenuItem>
                </Select>
              </FormControl>
            )}
          {['Missed', 'Positive'].includes(result) && toxType === 6 && (
            <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
              <InputLabel id="vendor-alert-label">BAC Entry</InputLabel>
              <Select
                labelId="vendor-alert-label"
                id="vendor-alert-select"
                value={selectedVendorAlert}
                onChange={(e) => setSelectedVendorAlert(e.target.value)}>
                <MenuItem value={''}>
                  <em>Not Applicable</em>
                </MenuItem>
                {vendorAlerts.map((alert) => (
                  <MenuItem key={alert._id} value={alert._id}>
                    {formatDate(alert.toxDate, alert.toxResult)} - {alert.toxResult}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            sx={{ mt: 2 }}
            error={hasError('Notes')}
            fullWidth
            helperText={hasError('Notes') ? formState.errors.Notes?.[0] : null}
            label="Notes"
            name="Notes"
            onChange={handleChange}
            value={formState.values.Notes || ''}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            label="Report File"
            name="file"
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              if (target.files) {
                setFile(target.files[0]);
              }
            }}
            type="file"
            InputLabelProps={{
              shrink: true
            }}
          />
          <FormControl sx={{ mt: 2, width: '100%' }} variant="standard">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selfReport}
                  onChange={handleSelfReport}
                  disabled={!auth.accountableAdmin}
                />
              }
              label="Was this a self report?"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            autoFocus
            disabled={
              !formState.isValid ||
              !result ||
              !toxType ||
              (toxType === 5 && !selectedKit && result !== 'Missed') // Disable if kit is not selected when Lab Confirmation and result is not Missed
            }>
            Save Toxicology
          </Button>
          <Button onClick={handleCancel} variant="contained" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTox;
